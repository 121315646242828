/* @import url("https://use.typekit.net/nvp8rdc.css"); */

@import url("https://fonts.googleapis.com/css2?family=Libre+Bodoni:wght@400;700&family=Montserrat:wght@400;500;600&display=swap");

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
img {
  display: block;
}

* {
  /* border: 1px red solid; */
}
